export function IntroText() {
  return (
    <div>
      <p>
        Welcome to the Coilcrypt
      </p>
      <p>
        Start by adding an item ☝
      </p>
    </div>
  );
}
